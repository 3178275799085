

.centerMyDiv{
    margin: 0 auto 0;
}


table.myTableBorder {
    border-spacing: 1em .5em;
    padding: 0 2em 1em 0;
    border: 1px solid orange;
}

/*table  td{
    width: 1.5em;
    height: 1.5em;
    background: #d2d2d2;
    text-align: center;
    vertical-align: middle;
}
table, td, th {
    border: 1px solid black;
}*/

.clickMe{
    cursor: pointer;
}

.pageContentBox{

   /* width: 100%;
    min-height: 75%;*/
    width: 100%;
    min-height: 100%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 2%;
}

.headerLogout{
    color: lightcoral;
    text-decoration: underline;

}

.headerMenuSelectSecondBar{
    color: yellow;
    font-weight: bold;
    font-size: 16px;
}
.borderRight{
    border-right-width: 1;
    border-right-color: black;
    border-right-style: solid;
}
.card-header{
    background-color: gray;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
}